'use client'

import { useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

// Configurations
import ThemeLightConfig from '@/configs/theme-light'
import ThemeDarkConfig from '@/configs/theme-dark'

// Types
import type { PaletteMode, Direction } from '@mui/material'
import type { ThemeRegistryProps } from '@/types/components/theme/theme-registry'

// Helpers
import { SettingsContext } from '@/helpers/context'
import { reduxStore } from '@/helpers/redux/store'

// Get theme
const getTheme = (mode: PaletteMode, direction: Direction) => {
	// Create my theme
	const myTheme = createTheme({ ...(mode == 'light' ? ThemeLightConfig : ThemeDarkConfig), ...{ direction } })

	return myTheme
}

export default function ThemeRegistry(props: ThemeRegistryProps) {
	// Props
	const { dir, children } = props

	// Variables
	const [mode, setMode] = useState<PaletteMode | 'system'>('light')

	// Settings
	const settings = reduxStore.getState().settings

	// Find user mode
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

	// Set mode
	useEffect(() => {
		if ((settings.mode == 'system' && prefersDarkMode) || settings.mode == 'dark') {
			if (mode != 'dark') setMode('dark')
		} else if (settings.mode == 'light') {
			if (mode != 'light') setMode('light')
		}
	}, [mode, prefersDarkMode, settings])

	return (
		<SettingsContext.Provider value={{ mode, setMode }}>
			<Provider store={reduxStore}>
				<ThemeProvider theme={getTheme(mode == 'system' ? (prefersDarkMode ? 'dark' : 'light') : mode, dir)}>
					<CssBaseline />
					{children}
				</ThemeProvider>
			</Provider>
		</SettingsContext.Provider>
	)
}
