import { createTheme } from '@mui/material/styles'

// Fonts
import CalibriFont from '@/styles/fonts/calibri'

const ThemeDarkConfig = createTheme({
	palette: {
		mode: 'dark',
		primary: { light: '#eec497', main: '#e39d52', dark: '#9c784b', contrastText: '#fff' },
		secondary: { light: '#75abf3', main: '#2f80ed', dark: '#112b3c', contrastText: '#fff' }
	},
	shape: { borderRadius: 16 },
	typography: {
		fontFamily: CalibriFont.style.fontFamily
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: { scrollBehavior: 'smooth' },
				a: { textDecoration: 'none' }
			}
		}
	}
})

export default ThemeDarkConfig
